// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as BannerLearnMore from "../../../../../styleguide/components/BannerLearnMore/BannerLearnMore.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../../../routes/common/Routes_Marketplace.res.js";
import MarketplacePng from "Images/home/Marketplace.png";
import ColoProjectsPng from "Images/home/ColoProjects.png";
import InternetProjectsPng from "Images/home/InternetProjects.png";
import * as LearnMoreSectionColocationScss from "./LearnMoreSectionColocation.scss";

var css = LearnMoreSectionColocationScss;

function LearnMoreSectionColocation(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                props.anchorToColocationComponent,
                JsxRuntime.jsx(BannerLearnMore.make, {
                      title: "Streamline Your Colocation RFPs",
                      subtitle: "Colocation project platform",
                      description: "Datacenters.com makes it easy to get colocation quotes from multiple colocation providers. Get started by entering your colocation service requirements. Then, simply select from the providers and data center facilities matching your requirements. Submit your finalized colocation quote directly to the providers for pricing.",
                      isReversed: false,
                      svgIllustration: ColoProjectsPng,
                      ctaComponent: Caml_option.some(JsxRuntime.jsx(Button.AsNativeLink.make, {
                                href: Routes_Project.Colocation.$$new,
                                size: "MD",
                                color: "Teal",
                                children: "Start a Colocation RFP"
                              }))
                    }),
                props.anchorToInternetNetworkComponent,
                JsxRuntime.jsx(BannerLearnMore.make, {
                      title: "Global Internet and Network Access",
                      subtitle: "Internet & Network project platform",
                      description: "Searching for Internet and network services to connect your locations, physical hardware and virtual servers? Datacenters.com offers a quoting tool that allows you to request pricing for your locations and services. Select from Ethernet, SD-WAN, MPLS and private line services. Connect directly with leading telecom carriers, ISPs and network service providers.",
                      isReversed: true,
                      svgIllustration: InternetProjectsPng,
                      ctaComponent: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(Button.AsNativeLink.make, {
                                        href: Routes_Project.Internet.$$new,
                                        size: "MD",
                                        color: "Teal",
                                        children: "Request Internet Quotes"
                                      }),
                                  JsxRuntime.jsx(Link.make, {
                                        href: Routes_Project.Network.$$new,
                                        children: "Get Network Quotes"
                                      })
                                ],
                                className: css.ctaButtonsRow
                              }))
                    }),
                props.anchorToMarketplaceComponent,
                JsxRuntime.jsx(BannerLearnMore.make, {
                      title: "Shop Colocation Pricing, and Hosting Services",
                      subtitle: "Colocation Marketplace",
                      description: "Get exclusive deals and discounts from top providers on colocation pricing, dedicated servers and hosting services. Reserve your pricing online directly from the provider or customize your service using the Datacenters.com RFP Platform.",
                      isReversed: false,
                      svgIllustration: MarketplacePng,
                      ctaComponent: Caml_option.some(JsxRuntime.jsx(Button.AsNativeLink.make, {
                                href: Routes_Marketplace.colocation,
                                size: "MD",
                                color: "Teal",
                                children: "View Colocation Pricing"
                              }))
                    })
              ],
              className: css.wrapper
            });
}

var make = LearnMoreSectionColocation;

export {
  css ,
  make ,
}
/* css Not a pure module */
