// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as Faq from "../../../../../styleguide/components/Faq/Faq.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FAQColocationScss from "./FAQColocation.scss";

var css = FAQColocationScss;

function FAQColocation(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.heading,
                      children: "Learn More About Colocation"
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "What colocation services are available?",
                      answer: "There are " + String(props.totalLocations) + " data centers listed in the Datacenters.com directory, many of which offer colocation are available on the colocation project platform."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "How do I sign up and start a colocation project?",
                      answerWithLink: Caml_option.some(JsxRuntime.jsxs("div", {
                                children: [
                                  "Click on \"Start a Project\" in the navigation and select \"Colocation\" from the dropdown menu. Or click ",
                                  JsxRuntime.jsx(Link.make, {
                                        href: Routes_Project.Colocation.$$new,
                                        children: "here."
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "What regions of the world are served?",
                      answer: "Our directory includes data centers from " + String(props.totalCountries) + " countries. When you start a colocation project, just search for a country or city where you need services."
                    }),
                JsxRuntime.jsx(Faq.make, {
                      question: "Why should I use the Datacenters.com Colocation Project Platform?",
                      answer: "When you create a colocation project, your project is intelligently matched to the providers and data centers that meet your unique requirements. Your RFP is sent directly to the providers who will compete for your business. Our Concierge team will also contact you to offer free advice and help pick a solution that's right for you!"
                    })
              ],
              className: css.wrapper
            });
}

var make = FAQColocation;

export {
  css ,
  make ,
}
/* css Not a pure module */
