// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FAQColocation from "./components/faqs/FAQColocation.res.js";
import * as WhyColocation from "./components/why-colocation/WhyColocation.res.js";
import * as ColocationScss from "./Colocation.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIcons from "../../../styleguide/components/BannerProviderIcons/BannerProviderIcons.res.js";
import * as DirectoryColocation from "./components/directory/DirectoryColocation.res.js";
import * as JumbotronColocation from "./components/jumbotron/JumbotronColocation.res.js";
import * as SubNavigationColocation from "./components/sub-navigation/SubNavigationColocation.res.js";
import * as LearnMoreSectionColocation from "./components/learn-more/LearnMoreSectionColocation.res.js";
import Coloc_leftSvg from "./components/assets/coloc_left.svg";
import Coloc_rightSvg from "./components/assets/coloc_right.svg";

var css = ColocationScss;

var colocHeaderLeftSvg = Coloc_leftSvg;

var colocHeaderRightSvg = Coloc_rightSvg;

function Colocation$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronColocation.make, {}),
                            className: css.backgroundImage,
                            style: {
                              backgroundImage: "url(" + colocHeaderLeftSvg + "), url(" + colocHeaderRightSvg + ")"
                            }
                          }),
                      className: css.jumbotronSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(BannerProviderIcons.make, {
                            context: "Colocation"
                          }),
                      className: css.providerIconsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(SubNavigationColocation.make, {}),
                      className: css.subNavigationColocationSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(LearnMoreSectionColocation.make, {
                                  anchorToColocationComponent: JsxRuntime.jsx("div", {
                                        className: css.anchor,
                                        id: "colocation_project_platform"
                                      }),
                                  anchorToInternetNetworkComponent: JsxRuntime.jsx("div", {
                                        className: css.anchor,
                                        id: "colocation_internet_network"
                                      }),
                                  anchorToMarketplaceComponent: JsxRuntime.jsx("div", {
                                        className: css.anchor,
                                        id: "colocation_marketplace"
                                      })
                                }),
                            className: css.wideContainer
                          }),
                      className: css.learnMoreColocationSection
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "colocation_directory"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(DirectoryColocation.make, {
                            totalProviders: props$1.totalProviders,
                            totalLocations: props$1.totalLocations
                          }),
                      className: css.colocationDirectorySection
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "colocation_why"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(WhyColocation.make, {}),
                      className: css.whyColocationSection
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "colocation_faq"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(FAQColocation.make, {
                            totalLocations: props$1.totalLocations,
                            totalCountries: props$1.totalCountries
                          }),
                      className: css.faqSection
                    })
              ],
              className: css.wrapper
            });
}

var $$default = Colocation$default;

export {
  css ,
  colocHeaderLeftSvg ,
  colocHeaderRightSvg ,
  $$default as default,
}
/* css Not a pure module */
