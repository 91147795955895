// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerReasons from "../../../../../styleguide/components/BannerReasons/BannerReasons.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerSingleReason from "../../../../../styleguide/components/BannerReasons/BannerSingleReason.res.js";
import IconComplianceSvg from "../assets/icon-compliance.svg";
import IconReliabilitySvg from "../assets/icon-reliability.svg";
import IconCostOwnershipSvg from "../assets/icon-cost-ownership.svg";
import IconScaleUpNDownSvg from "../assets/icon-scale-up-n-down.svg";

var iconScaleUpNDown = IconScaleUpNDownSvg;

var iconCompliance = IconComplianceSvg;

var iconReliability = IconReliabilitySvg;

var iconCostOwnership = IconCostOwnershipSvg;

function WhyColocation(props) {
  return JsxRuntime.jsxs(BannerReasons.make, {
              title: "Why Colocation?",
              description: "A colocation data center is a physical facility that offers space with the proper power, cooling and security to host businesses' computing hardware and servers. This capacity includes anything from cabinets to cages or private suites.",
              children: [
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Scalability",
                      description: "High-power bandwidth and network resources available on demand.",
                      svgIcon: iconScaleUpNDown
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Security & Compliance",
                      description: "Our data centers include the highest levels of security and compliance the industry has to offer.",
                      svgIcon: iconCompliance
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Reliability",
                      description: "Access emerging markets like China, India, Brazil, Indonesia, Western Africa, South Africa, and more.",
                      svgIcon: iconReliability
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Cost of Ownership",
                      description: "Our advisors help you secure flexible billing options and negotiate multi-year contracts.",
                      svgIcon: iconCostOwnership
                    })
              ]
            });
}

var make = WhyColocation;

export {
  iconScaleUpNDown ,
  iconCompliance ,
  iconReliability ,
  iconCostOwnership ,
  make ,
}
/* iconScaleUpNDown Not a pure module */
