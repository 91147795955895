// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../../../styleguide/components/Heading/H3.res.js";
import * as Routes_Location from "../../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DirectoryColocationScss from "./DirectoryColocation.scss";
import IconProvidersSvg from "../assets/icon-providers.svg";
import IconDataCentersSvg from "../assets/icon-data-centers.svg";

var css = DirectoryColocationScss;

var iconDataCenters = IconDataCentersSvg;

var iconProviders = IconProvidersSvg;

function DirectoryColocation$DirectoryCard(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: props.iconComponent,
                      className: css.iconComponent
                    }),
                JsxRuntime.jsx(H3.make, {
                      className: css.heading,
                      children: props.title
                    }),
                JsxRuntime.jsx("div", {
                      children: props.description,
                      className: css.description
                    }),
                JsxRuntime.jsx("div", {
                      children: props.ctaComponent
                    })
              ],
              className: css.card
            });
}

var DirectoryCard = {
  make: DirectoryColocation$DirectoryCard
};

function DirectoryColocation(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.mainHeading,
                      children: "Data Center Directory and Colocation Providers"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(DirectoryColocation$DirectoryCard, {
                              title: String(props.totalLocations) + " Data Centers",
                              description: "Find data center locations. Click data centers near me to view datacenters within a specific radius.",
                              iconComponent: JsxRuntime.jsx("img", {
                                    src: iconDataCenters
                                  }),
                              ctaComponent: JsxRuntime.jsx(A.make, {
                                    href: Routes_Location.index,
                                    children: "Search Data Centers"
                                  })
                            }),
                        JsxRuntime.jsx(DirectoryColocation$DirectoryCard, {
                              title: String(props.totalProviders) + " Providers",
                              description: "Find colocation providers and cloud service providers by searching our verified data center database including top markets, and providers in the USA and Internationally.",
                              iconComponent: JsxRuntime.jsx("img", {
                                    src: iconProviders
                                  }),
                              ctaComponent: JsxRuntime.jsx(A.make, {
                                    href: Routes_Provider.index,
                                    children: "View Providers"
                                  })
                            })
                      ],
                      className: css.cardsRow
                    })
              ]
            });
}

var make = DirectoryColocation;

export {
  css ,
  iconDataCenters ,
  iconProviders ,
  DirectoryCard ,
  make ,
}
/* css Not a pure module */
