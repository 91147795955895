// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../../utils/Url.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as SubNavigation from "../../../../../styleguide/components/SubNavigation/SubNavigation.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function SubNavigationColocation(props) {
  return JsxRuntime.jsxs(SubNavigation.make, {
              title: "Colocation",
              children: [
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#colocation_project_platform",
                      text: "RFP Platform"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#colocation_internet_network",
                      text: "Internet & Network"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#colocation_marketplace",
                      text: "Marketplace"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#colocation_directory",
                      text: "Directory"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#colocation_why",
                      text: "Why Colocation?"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#colocation_faq",
                      text: "FAQ"
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "SM",
                      color: "Primary",
                      onClick: (function (param) {
                          Url.visit(Routes_Project.Colocation.$$new);
                        }),
                      children: "Colocation RFP"
                    })
              ]
            });
}

var make = SubNavigationColocation;

export {
  make ,
}
/* Button Not a pure module */
