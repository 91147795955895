// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ScheduleModalServices from "../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as JumbotronColocationScss from "./JumbotronColocation.scss";

var css = JumbotronColocationScss;

function JumbotronColocation(props) {
  var match = React.useState(function () {
        return "Closed";
      });
  var setModalState = match[1];
  var tmp;
  tmp = match[0] === "Opened" ? JsxRuntime.jsx(ScheduleModalServices.make, {
          onClose: (function () {
              setModalState(function (param) {
                    return "Closed";
                  });
            }),
          title: "Speak to a Colocation Expert",
          subtitle: "Submit the form to schedule time with an expert and learn more about colocation solutions.",
          buttonTitle: "Submit",
          leadType: "Colocation"
        }) : null;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      className: css.heading,
                      children: "Enterprise IT Made Easy"
                    }),
                JsxRuntime.jsx("div", {
                      children: "The #1 platform for building your IT infrastructure. Connect directly with top data centers, network, peering and cloud providers.",
                      className: css.headingExplainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Primary",
                              expanded: true,
                              onClick: (function (param) {
                                  Url.visit(Routes_Project.Colocation.$$new);
                                }),
                              children: "Start Colocation RFP"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Clear",
                              ghost: true,
                              expanded: true,
                              onClick: (function (param) {
                                  setModalState(function (param) {
                                        return "Opened";
                                      });
                                }),
                              children: "Speak to an Expert"
                            })
                      ],
                      className: css.buttonsRow
                    }),
                tmp
              ],
              className: css.wrapper
            });
}

var make = JumbotronColocation;

export {
  css ,
  make ,
}
/* css Not a pure module */
